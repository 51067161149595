<template>
    <div :class="CSSClasses" class="avatar-set">
        <ul class="avatars">
            <template v-for="(person, index) in displayPeople">
                <template v-if="index < 4">
                    <li :key="person.id" class="person">
                        <Avatar
                            v-if="index < 3"
                            :key="person.id"
                            :url="person.path"
                            :image="person.avatar"
                            :size="size"
                            class="avatar"
                        />
                        <Avatar
                            v-else-if="index === 3"
                            :url="person.path"
                            :image="person.avatar"
                            class="avatar placeholder"
                            :size="size"
                        >
                            <Typography :variant="variant">+{{ displayPeople.length - index }}</Typography>
                        </Avatar>
                    </li>
                </template>
            </template>
        </ul>

        <ul class="names" v-if="!hideNames">
            <li v-for="(person, index) in displayPeople" :key="person.id" class="name">
                <Typography variant="body-micro">
                    <Anchor
                        v-if="person.path && (person.type === 'internal' || person.hasProfile)"
                        :url="person.path"
                        >{{ getPersonFullName(person) }}</Anchor
                    >
                    <span v-else>{{ getPersonFullName(person) }}</span>

                    <template v-if="index !== displayPeople.length - 1">,&nbsp;</template>
                </Typography>
            </li>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    people: {
        type: Array,
        default: () => [],
    },
    theme: {
        ...themes,
    },
    hideNames: {
        type: Boolean,
        default: false,
    },
    hideArchived: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'small',
        validator: (value) => {
            return ['micro', 'small'].includes(value);
        },
    },
    variant: {
        type: String,
        default: 'label-small',
        validator: (value) => {
            return ['label-micro', 'label-small'].includes(value);
        },
    },
});

const displayPeople = computed(() => {
    if (props.hideArchived) {
        return props.people.filter((data) => !data.isArchived);
    } else {
        return props.people;
    }
});

const CSSClasses = computed(() => {
    return [`theme-${props.theme}`];
});
</script>

<style lang="scss" scoped>
.avatar-set {
    display: contents;
    gap: 0.4rem;
    max-width: 90%;
    align-items: center;

    &.theme-dark {
        .names {
            color: color(blanc, default, 0.6);
        }
    }
}

.avatars {
    display: flex;
    align-items: center;
}

.avatar {
    background: color(blue);
    border: 1px solid color(blanc);
    box-sizing: border-box;
    border-radius: 100rem;

    .person:not(:first-child) & {
        margin-inline-start: -0.5rem;
    }
}

.names {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: color(offnoir, default, 0.6);
    @include media-query(phone) {
        white-space: wrap;
    }
}

.name {
    display: inline-block;
}
</style>
